import React from "react";
import styled from "styled-components";
import { MaxWidth, maxMedia, minMedia } from "../utils/style";

import { FaLinkedin, FaYoutube, FaXing, FaTwitter } from "react-icons/fa";
import { EasyGrid } from "./styles/EasyGrid";
import { IconButton, LinkButton, Button } from "./styles/LinkButton";
import { Observer, useObservable } from "mobx-react-lite";
import { SocialLinks } from "../utils/const";

interface Props {}

export const Div = styled.div`
  display: flex;
  margin-bottom: 3rem;

  ${maxMedia.tablet`
    flex-direction: column;
    > .right {
      margin-top: 3rem;
    }
  `}

  ${minMedia.tablet`
    flex-direction: row;
    align-items: center;
    > *:not(:last-child) {
      margin-right: 5rem;
    }

    > .left {
      flex: 1 1 50%;
    }

    > .right {
      flex: 1 1 50%;
    }
  `}
`;

export const ContactForm = (props: Props) => {
  return (
    <MaxWidth>
      <h2 style={{ textAlign: "center", margin: "3rem" }}>Kontakt</h2>

      <Div>
        <div className="left">
          <h4>Kontaktaufnahme</h4>
          <p>
            Bitte nehmen Sie Kontakt zu uns auf entweder mit dem nebenstehenden
            Kontaktformular oder mit den unten angegeben Möglichkeiten.
          </p>

          <p>
            Neun Consulting Group
            <br />
            Postweg 7
            <br />
            78256 Steißlingen
          </p>

          <p>
            Tel: +49 (0)7533 / 9359-00
            <br />
            Email: info@neunconsultinggroup.de
          </p>

          <h4>Kontaktaufnahme</h4>

          <EasyGrid>
            {/* <IconButton href={SocialLinks.youtube} color="#D7143A">
              <FaYoutube />
            </IconButton> */}
            <IconButton href={SocialLinks.linkedIn} color="#0077B7">
              <FaLinkedin color="#0077B7" />
            </IconButton>
            <IconButton href={SocialLinks.xing} color="#00605E">
              <FaXing />
            </IconButton>
            <IconButton href={SocialLinks.twitter} color="#0077B7">
              <FaTwitter color="#0077B7" />
            </IconButton>
          </EasyGrid>
        </div>
        <div className="right">
          <ContactFormInner />
        </div>
      </Div>
    </MaxWidth>
  );
};

const ContactFormInner = () => {
  const data = useObservable({
    name: "",
    email: "",
    message: "",
    isSubmitted: false,
  });

  const getMsg = () => `Neue Kontaktanfrage
===================

Name: ${data.name}
Email: ${data.email}
Nachricht: 
${data.message}
--- ENDE ---
`;

  return (
    <Observer>
      {() => (
        <div id="kontakt">
          {data.isSubmitted && (
            <div>
              <h4>Kontaktanfrage erfolgreich versendet.</h4>
              <p>
                Vielen Dank für Ihre Kontaktanfrage. Wir werden uns
                schnellstmöglich bei Ihnen melden.
              </p>
            </div>
          )}

          {!data.isSubmitted && (
            <Form
              action=""
              onSubmit={async (e) => {
                e.preventDefault();

                var formData = new FormData();

                formData.append("name", data.name);
                formData.append("email", data.email);
                formData.append("message", `SPEAKER SEITE - ${data.message}`);

                data.isSubmitted = true;

                const res = await fetch(
                  "https://public.kom-neun.de/wn-api/dev.php",
                  {
                    method: "POST",
                    body: formData,
                  }
                );

                console.log(res);
              }}
            >
              <div>
                <label htmlFor="">Name:</label>
                <input
                  value={data.name}
                  onChange={(e) => (data.name = e.target.value)}
                  required
                />
              </div>

              <div>
                <label htmlFor="">Email:</label>
                <input
                  value={data.email}
                  onChange={(e) => (data.email = e.target.value)}
                  required
                />
              </div>

              <div>
                <label htmlFor="">Nachricht:</label>
                <textarea
                  value={data.message}
                  onChange={(e) => (data.message = e.target.value)}
                  style={{ height: 150 }}
                />
              </div>

              <div>
                <Button type="submit">Abschicken</Button>
              </div>
            </Form>
          )}
        </div>
      )}
    </Observer>
  );
};

export const Form = styled.form`
  > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  input,
  textarea {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ddd;
    resize: vertical;
    padding: 0.5rem;
  }

  label {
    font-size: 0.85rem;
    font-weight: bold;
    display: block;
    margin-bottom: 0.5rem;
  }
`;
